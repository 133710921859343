<template>
  <div>
    <the-advisor-layout-header headline="Profile & Settings" />

    <v-card width="900" class="ml-14 mt-6" outlined>
      <v-card color="transparent" flat>
        <v-row no-gutters>
          <v-col cols="2" class="text-right">
            <v-card color="transparent" flat>
              <v-card-text class="headline primary--text">
                Personal
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-btn
              @click="dialogPersonal = !dialogPersonal"
              small
              text
              depressed
              class="mt-4"
            >
              <v-icon color="success" class="title">mdi-pencil</v-icon
              >EDIT</v-btn
            >
          </v-col>
          <v-col cols="2" class="text-right">
            <v-card color="transparent" flat>
              <v-card-text class="headline primary--text">
                Email
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-btn
              @click="dialogEmail = !dialogEmail"
              small
              text
              depressed
              class="mt-4"
            >
              <v-icon color="success" class="title">mdi-pencil</v-icon
              >EDIT</v-btn
            >
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="2" class="text-right"
            ><v-card-text class="subtitle-1 grey--text">
              Name:
            </v-card-text></v-col
          >
          <v-col cols="2"
            ><v-card-text class="subtitle-1">
              {{ advisorProfile.title }} {{ advisorProfile.name }}
            </v-card-text></v-col
          >
          <v-col cols="2"></v-col>
          <v-col cols="2" class="text-right">
            <div v-if="userEmailVerified">
              <v-card-text class="subtitle-1"
                ><v-icon left>mdi-email</v-icon>verified
                <v-icon color="green">mdi-check</v-icon>
              </v-card-text>
            </div>
            <div v-else>
              <v-card-text class="subtitle-1 grey--text">
                Email address:
              </v-card-text>
            </div>
          </v-col>
          <v-col cols="4">
            <v-card-text class="subtitle-1">
              {{ userEmail }}
            </v-card-text>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="2" class="text-right"> </v-col>
          <v-col cols="4"> </v-col>
          <v-col cols="2" class="text-right"> </v-col>
          <v-col cols="4"> </v-col>
        </v-row>

        <v-divider class="success mx-4"></v-divider>

        <v-row no-gutters>
          <v-col cols="2" class="text-right"
            ><v-card color="transparent" flat>
              <v-card-text class="headline primary--text mt-3">
                Address
              </v-card-text>
            </v-card></v-col
          >
          <v-col cols="4"
            ><v-btn
              @click="dialogAddress = !dialogAddress"
              small
              text
              depressed
              class="mt-7"
            >
              <v-icon color="success" class="title">mdi-pencil</v-icon
              >edit</v-btn
            >
          </v-col>
          <v-col cols="2" class="text-right"
            ><v-card color="transparent" flat>
              <v-card-text class="headline primary--text mt-3">
                Phone
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4"
            ><v-btn
              @click="dialogPhone = !dialogPhone"
              small
              text
              depressed
              class="mt-7"
            >
              <v-icon color="success" class="title">mdi-pencil</v-icon
              >edit</v-btn
            >
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="2" class="text-right">
            <v-card-text class="subtitle-1 grey--text">
              Residential:
            </v-card-text>
          </v-col>
          <v-col cols="4">
            <v-card-text class="subtitle-1">
              {{ advisorProfile.resNumber }}
              {{ advisorProfile.resStreet }}
            </v-card-text>
          </v-col>
          <v-col cols="2" class="text-right">
            <v-card-text class="subtitle-1 grey--text">
              Mobile:
            </v-card-text>
          </v-col>
          <v-col cols="4">
            <v-card-text class="subtitle-1">
              {{ userPhone }}
            </v-card-text>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="2" class="text-right"> </v-col>
          <v-col cols="4">
            <v-card-text class="subtitle-1 mt-n8">
              {{ advisorProfile.resSuburb }}
            </v-card-text>
          </v-col>
          <v-col cols="2" class="text-right">
            <v-card-text class="subtitle-1 grey--text mt-n8">
              Work:
            </v-card-text>
          </v-col>
          <v-col cols="4">
            <v-card-text class="subtitle-1  mt-n8">
              {{ advisorProfile.workPhone }}
            </v-card-text>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="2" class="text-right"> </v-col>
          <v-col cols="4">
            <v-card-text class="subtitle-1 mt-n8">
              {{ advisorProfile.resCity }}
              {{ advisorProfile.resPostcode }}
            </v-card-text>
          </v-col>
          <v-col cols="2" class="text-right">
            <v-card-text class="subtitle-1 grey--text mt-n8">
              Home:
            </v-card-text>
          </v-col>
          <v-col cols="4">
            <v-card-text class="subtitle-1 mt-n8">
              {{ advisorProfile.homePhone }}
            </v-card-text>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="2" class="text-right"> </v-col>
          <v-col cols="4">
            <v-card-text class="subtitle-1 mt-n8">
              {{ advisorProfile.resCountry }}
            </v-card-text>
          </v-col>
          <v-col cols="2" class="text-right"> </v-col>
          <v-col cols="4"> </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="2" class="text-right">
            <v-card-text class="subtitle-1 grey--text mt-n4">
              Mailing:
            </v-card-text>
          </v-col>
          <v-col cols="4">
            <v-card-text class="subtitle-1 mt-n4">
              {{ advisorProfile.mailNumber }}
              {{ advisorProfile.mailStreet }}
            </v-card-text>
          </v-col>
          <v-col cols="2" class="text-right"> </v-col>
          <v-col cols="4"> </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="2" class="text-right"> </v-col>
          <v-col cols="4">
            <v-card-text class="subtitle-1 mt-n8">
              {{ advisorProfile.mailSuburb }}
            </v-card-text>
          </v-col>
          <v-col cols="2" class="text-right"> </v-col>
          <v-col cols="4"> </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="2" class="text-right"> </v-col>
          <v-col cols="4">
            <v-card-text class="subtitle-1 mt-n8">
              {{ advisorProfile.mailCity }}
              {{ advisorProfile.mailPostcode }}
            </v-card-text>
          </v-col>
          <v-col cols="2" class="text-right"> </v-col>
          <v-col cols="4"> </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="2" class="text-right"> </v-col>
          <v-col cols="4">
            <v-card-text class="subtitle-1 mt-n8">
              {{ advisorProfile.mailCountry }}
            </v-card-text>
          </v-col>
          <v-col cols="2" class="text-right"> </v-col>
          <v-col cols="4"> </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="2" class="text-right"> </v-col>
          <v-col cols="4"> </v-col>
          <v-col cols="2" class="text-right"> </v-col>
          <v-col cols="4"> </v-col>
        </v-row>

        <v-divider class="success mx-4"></v-divider>

        <v-row no-gutters>
          <v-col cols="2" class="text-right"
            ><v-card color="transparent" flat>
              <v-card-text class="headline primary--text mt-3">
                Security
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4">
            <div v-if="cognitoUserPreferredMFA == 'TOTP'">
              <v-card-text class="subtitle-1 mt-4"
                ><v-icon left>mdi-shield-account</v-icon>2FA
                <v-icon color="green">mdi-check</v-icon>
              </v-card-text>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="2" class="text-right">
            <v-card-text class="subtitle-1 grey--text">
              Password:
            </v-card-text>
          </v-col>
          <v-col cols="4">
            <v-btn
              small
              @click="dialogPassword = true"
              color="text--primary"
              class="mt-4 ml-2"
              >Change</v-btn
            >
          </v-col>
          <v-col cols="2" class="text-right">
            <v-card-text class="subtitle-1 grey--text">
              2FA:
            </v-card-text>
          </v-col>
          <v-col cols="4"
            ><div v-if="cognitoUserPreferredMFA == 'NOMFA'">
              <v-btn small @click="setup2FA" color="success" class="mt-4 ml-2">
                <v-icon left class="success headline">mdi-shield-account</v-icon
                >Enable</v-btn
              >
            </div>
            <div v-else>
              <v-btn
                small
                @click="dialogDisable2FA = true"
                color="error"
                class="mt-4 ml-2"
              >
                <v-icon left class="headline">mdi-shield-account</v-icon
                >Disable</v-btn
              >
            </div>
          </v-col>
        </v-row>

        <v-divider class="success mx-4"></v-divider>

        <v-row no-gutters>
          <v-col cols="2" class="text-right"
            ><v-card-text class="subtitle-1 grey--text">
              Company Name:
            </v-card-text>
            <v-card-text class="subtitle-1 grey--text mt-n8">
              Company-ID:
            </v-card-text></v-col
          >
          <v-col cols="2"
            ><v-card-text class="subtitle-1">
              {{ tenantName }}
            </v-card-text>
            <v-card-text class="subtitle-1 mt-n8">
              {{ tenantId }}
            </v-card-text></v-col
          >
          <v-col cols="2" class="text-right">
            <v-card-text class="subtitle-1 grey--text">
              User-ID:
            </v-card-text>
            <v-card-text class="subtitle-1 grey--text mt-n8">
              User-Role:
            </v-card-text>
          </v-col>
          <v-col cols="6">
            <v-card-text class="subtitle-1">
              {{ userId }}
            </v-card-text>
            <v-card-text class="subtitle-1 mt-n8">
              {{ advisorProfile.role }}
            </v-card-text></v-col
          >
        </v-row>
      </v-card>
    </v-card>

    <v-dialog persistent v-model="dialogPersonal" width="400px">
      <v-card>
        <v-card-title class="headline ml-3">
          <span class="headline mt-4">Update your personal details</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container>
              <v-row>
                <v-col cols="4">
                  <v-select
                    v-model="profile.title"
                    :items="titleItems"
                    label="Title"
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="8"> </v-col>
                <v-col cols="6" class="mt-n5">
                  <v-text-field
                    v-model="profile.firstName"
                    :rules="nameReqRules"
                    label="First name"
                /></v-col>
                <v-col cols="6" class="mt-n5">
                  <v-text-field
                    v-model="profile.familyName"
                    :rules="nameReqRules"
                    label="Family name"
                  />
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions>
              <v-spacer />
              <v-btn text class="primary--text" @click="close">Cancel</v-btn>
              <v-btn
                text
                color="success"
                :disabled="!valid"
                @click="updatePersonal"
                >Update</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="dialogEmail" width="400px">
      <v-card>
        <v-card-title class="headline ml-3">
          <span class="headline mt-4">Change your Email address</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="emailAddress"
                    label="New Email address"
                    :rules="emailReqRules"
                  />
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions>
              <v-spacer />
              <v-btn
                text
                class="primary--text"
                @click="dialogEmail = !dialogEmail"
                >Cancel</v-btn
              >
              <v-btn
                text
                color="success"
                :disabled="!valid"
                @click="updateEmail"
                >Save & Verify</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="dialogAddress" width="550px">
      <v-card>
        <v-card-title class="headline ml-3">
          <span class="headline mt-4">Update your address details</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-card-text class="title ml-n4">
                    Residential Address
                  </v-card-text>
                  <v-text-field
                    v-model="profile.resNumber"
                    :rules="numberRules"
                    label="Number"
                    class="mt-7"
                  />
                  <v-text-field
                    v-model="profile.resStreet"
                    :rules="nameRules"
                    label="Street"
                  />
                  <v-text-field
                    v-model="profile.resSuburb"
                    :rules="nameRules"
                    label="Suburb"
                  />
                  <v-text-field
                    v-model="profile.resCity"
                    :rules="nameRules"
                    label="Town / City"
                  />
                  <v-text-field
                    v-model="profile.resPostcode"
                    :rules="numberRules"
                    label="Postcode"
                  />
                  <v-text-field
                    v-model="profile.resCountry"
                    :rules="nameRules"
                    label="Country"
                  />
                </v-col>
                <v-col cols="6">
                  <v-card-text class="title ml-n4">
                    Mailing Address
                  </v-card-text>
                  <v-btn
                    @click="copyResidential"
                    color="success"
                    text
                    small
                    class="mt-n5"
                  >
                    (copy residental address)</v-btn
                  >
                  <v-text-field
                    v-model="profile.mailNumber"
                    :rules="numberRules"
                    label="Number"
                  />
                  <v-text-field
                    v-model="profile.mailStreet"
                    :rules="nameRules"
                    label="Street"
                  />
                  <v-text-field
                    v-model="profile.mailSuburb"
                    :rules="nameRules"
                    label="Suburb"
                  />
                  <v-text-field
                    v-model="profile.mailCity"
                    :rules="nameRules"
                    label="Town / City"
                  />
                  <v-text-field
                    v-model="profile.mailPostcode"
                    :rules="numberRules"
                    label="Postcode"
                  />
                  <v-text-field
                    v-model="profile.mailCountry"
                    :rules="nameRules"
                    label="Country"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
          <v-card-actions>
            <v-spacer />
            <v-btn text class="primary--text" @click="close">Cancel</v-btn>
            <v-btn
              text
              color="success"
              :disabled="!valid"
              @click="updateAddress"
              >Update</v-btn
            >
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="dialogPhone" width="400px">
      <v-card>
        <v-card-title class="headline ml-3">
          <span class="headline mt-4">Update your phone details</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="profile.mobilePhone"
                    label="Mobile phone"
                    :rules="phoneReqRules"
                  />
                  <v-text-field
                    v-model="profile.workPhone"
                    label="Work phone"
                    :rules="phoneRules"
                  />
                  <v-text-field
                    v-model="profile.homePhone"
                    label="Home phone"
                    :rules="phoneRules"
                  />
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions>
              <v-spacer />
              <v-btn text class="primary--text" @click="close">Cancel</v-btn>
              <v-btn
                text
                color="success"
                :disabled="!valid"
                @click="updatePhone"
                >Update</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="dialogVerify" width="400px">
      <v-card>
        <v-card-title class="headline ml-3">
          <span class="headline mt-4">Check your Mailbox ...</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="formVerify" v-model="valid" lazy-validation>
            <p class="pl-2 ma-0 grey--text font-weight-medium">
              Enter the verification code you received via E-Mail to confirm
              your new E-Mail address.
            </p>
            <br />
            <v-text-field
              v-model="emailAddress"
              :rules="emailReqRules"
              type="text"
              prepend-icon="mdi-email"
              label="E-Mail"
            />
            <v-text-field
              v-model="verificationCode"
              :rules="numberRulesCode"
              prepend-icon="mdi-alpha-c-circle"
              label="Verification Code"
            />
            <br />
            <v-divider></v-divider>
            <br />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                text
                color="success"
                :disabled="!valid"
                @click="confirmCognitoCode"
                >Confirm</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="dialog2FA" width="520px">
      <v-card>
        <v-card-title class="headline ml-3">
          <span class="headline mt-4">Two-Factor Authentication Settings</span>
        </v-card-title>
        <v-card-text class="subtitle-1 primary--text mt-2 ml-3 mr-3"
          >Improves account security by adding a second authentication method
          beyond the login password.
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="subtitle-1 primary--text mt-2 ml-3 mr-3"
          >Instructions:
        </v-card-text>
        <v-card-text class="subtitle-2 primary--text mt-n2 ml-3 mr-8"
          >1. Open a mobile app like Google Athenticator, that generates a
          dynamic 6-digit one-time password, on your Smartphone
        </v-card-text>
        <v-card-text class="subtitle-2 primary--text mt-n2 ml-3 mr-3"
          >2. Scan the QR-code generated below with the mobile App
        </v-card-text>
        <v-card-text class="subtitle-2 primary--text mt-n2 ml-3 mr-3"
          >3. Enter the one-time passcode, generated by the mobile App, in the
          passcode box below
        </v-card-text>
        <v-card-text>
          <v-form
            ref="formVerify"
            v-model="valid"
            lazy-validation
            @submit="confirm2FA"
            onSubmit="return false;"
            class="mt-n4"
          >
            <br />
            <v-row>
              <v-col cols="3"></v-col>
              <v-col cols="6"
                ><v-card class="ml-3" width="200px">
                  <qrcode
                    :value="codeStr2FA"
                    :options="{ width: 200 }"
                  ></qrcode> </v-card
              ></v-col>
              <v-col cols="3"></v-col>
            </v-row>
            <br />
            <v-row>
              <v-col cols="3"></v-col>
              <v-col cols="6"
                ><v-text-field
                  v-model="verificationCode"
                  :rules="numberRulesCode"
                  label="enter 6-digit passcode"
                  outlined
                  dense
              /></v-col>
              <v-col cols="3"></v-col>
            </v-row>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                text
                class="primary--text mt-n2"
                @click="dialog2FA = !dialog2FA"
                >Cancel</v-btn
              >
              <v-btn
                text
                class="mt-n2"
                color="success"
                :disabled="!valid"
                @click="confirm2FA"
                >Verify</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="dialogDisable2FA" width="520px">
      <v-card>
        <v-card-title class="headline ml-3 mb-4">
          <span class="headline mt-4">Disable Two-Factor Authentication</span>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="formVerify"
            v-model="valid"
            lazy-validation
            @submit="disable2FA"
            onSubmit="return false;"
            class="mt-n4"
          >
            <v-row>
              <v-col cols="3"></v-col>
              <v-col cols="6"
                ><v-text-field
                  v-model="verificationCode"
                  :rules="numberRulesCode"
                  label="enter 6-digit passcode"
                  outlined
                  dense
              /></v-col>
              <v-col cols="3"></v-col>
            </v-row>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                text
                class="primary--text mt-n2"
                @click="dialogDisable2FA = !dialogDisable2FA"
                >Cancel</v-btn
              >
              <v-btn
                text
                class="mt-n2"
                color="success"
                :disabled="!valid"
                @click="disable2FA"
                >Verify</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="dialogPassword" width="520px">
      <v-card>
        <v-card-title class="headline ml-3 mb-4">
          <span class="headline mt-4">Change Password</span>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="formVerify"
            v-model="valid"
            lazy-validation
            @submit="changePassword"
            onSubmit="return false;"
            class="mt-n4"
          >
            <v-row>
              <v-col cols="3"></v-col>
              <v-col cols="6"
                ><v-text-field
                  v-model="oldPassword"
                  :type="showOldPassword ? 'text' : 'password'"
                  validate-on-blur
                  :append-icon="showOldPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  prepend-icon="mdi-lock"
                  label="Current Password"
                  @click:append="showOldPassword = !showOldPassword"
                  required
                  outlined
                  dense/>
                <v-text-field
                  v-model="newPassword"
                  :type="showNewPassword ? 'text' : 'password'"
                  :rules="passwordRules"
                  validate-on-blur
                  :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  prepend-icon="mdi-lock"
                  label="New Password"
                  @click:append="showNewPassword = !showNewPassword"
                  required
                  outlined
                  dense/>
                <v-text-field
                  v-model="reNewPassword"
                  :type="showReNewPassword ? 'text' : 'password'"
                  :rules="[passwordConfirmationRule]"
                  validate-on-blur
                  :append-icon="showReNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  prepend-icon="mdi-lock"
                  label="Confirm New Password"
                  @click:append="showReNewPassword = !showReNewPassword"
                  required
                  outlined
                  dense
              /></v-col>
              <v-col cols="3"></v-col>
            </v-row>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                text
                class="primary--text mt-n2"
                @click="dialogPassword = !dialogPassword"
                >Cancel</v-btn
              >
              <v-btn text class="mt-n2" color="success" @click="changePassword"
                >Verify</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import store from '@/vuex/store'
import { Auth } from 'aws-amplify'
import { mapState } from 'vuex'
import { authComputed } from '@/vuex/helpers.js'
import TheAdvisorLayoutHeader from '@/components/TheAdvisorLayoutHeader.vue'

export default {
  name: 'AdvisorProfile',

  // Navigation Route Guard to preload API Data before page is being shown
  beforeRouteEnter(routeTo, routeFrom, next) {
    store.dispatch('initDbAdvisorProfile').then(() => {
      // making sure, when navigating to this page, the slider allways moves to "SETTINGS" ( tab = 3 )
      store.commit('SET_ADVISOR_LAYOUT_TAB', 3)
      next()
    })
  },

  components: {
    TheAdvisorLayoutHeader
  },

  data() {
    return {
      valid: true,
      dialogPersonal: false,
      dialogAddress: false,
      dialogPhone: false,
      dialogEmail: false,
      dialogVerify: false,
      dialogPassword: false,
      dialog2FA: false,
      dialogDisable2FA: false,
      verificationCode: '',
      setup2FAcode: '',
      emailAddress: '',
      oldPassword: '',
      newPassword: '',
      reNewPassword: '',
      showOldPassword: false,
      showNewPassword: false,
      showReNewPassword: false,
      titleItems: ['Mr', 'Mrs', 'Ms', 'Miss'],
      genderItems: ['male', 'female'],
      nameRules: [
        v =>
          !v || (v && v.length <= 20) || 'Name must be less than 20 characters'
      ],
      nameReqRules: [
        v => !!v || 'Field is required',
        v =>
          !v || (v && v.length <= 20) || 'Name must be less than 20 characters'
      ],
      numberRules: [
        v => !v || /^(?=.*\d).+$/.test(v) || 'Requires numbers only'
      ],
      numberRulesCode: [
        v => !!v || 'Code is required',
        v =>
          (/^(?=.*\d).+$/.test(v) && v.length == 6) ||
          'Code must be 6 characters'
      ],
      phoneRules: [
        v => !v || /^[+]+\d+$/.test(v) || 'Use this form ... +64210123456'
      ],
      phoneReqRules: [
        v => !!v || 'Phone is required',
        v => !v || /^[+]+\d+$/.test(v) || 'Use this form ... +64210123456'
      ],
      emailReqRules: [
        v => !!v,
        v => !v || (!!v && /.+@.+\..+/.test(v)) || 'E-mail must be valid'
      ],
      passwordRules: [
        v => !!v || 'Password is required',
        v =>
          (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/.test(v) && v.length >= 8) ||
          'Uppercase, lowercase, Number and at least 8 Characters req.'
      ]
    }
  },

  methods: {
    copyResidential() {
      this.profile.mailNumber = this.profile.resNumber
      this.profile.mailStreet = this.profile.resStreet
      this.profile.mailSuburb = this.profile.resSuburb
      this.profile.mailCity = this.profile.resCity
      this.profile.mailPostcode = this.profile.resPostcode
      this.profile.mailCountry = this.profile.resCountry
    },
    updatePersonal() {
      this.dialogPersonal = false
      this.$store.dispatch('updateDbAdvisorProfile')
      this.$store.dispatch('updateCognitoUserName')
    },
    updateEmail() {
      this.dialogEmail = false
      this.$store.dispatch('updateCognitoEmail', this.emailAddress)
      this.dialogVerify = true
    },
    confirmCognitoCode() {
      if (this.$refs.formVerify.validate()) {
        this.$store.dispatch('confirmCognitoCode', this.verificationCode)
        this.dialogVerify = false
      }
    },
    updateAddress() {
      this.dialogAddress = false
      this.$store.dispatch('updateDbAdvisorProfile')
    },
    updatePhone() {
      this.$store.dispatch('updateDbAdvisorProfile')
      this.$store.dispatch('updateCognitoPhone')
      this.dialogPhone = false
    },
    close() {
      this.$store.dispatch('readDbAdvisorProfile')
      this.dialogAddress = false
      this.dialogPhone = false
      this.dialogPersonal = false
    },
    async setup2FA() {
      let user = await Auth.currentAuthenticatedUser()
      await Auth.setupTOTP(user)
        .then(code => {
          console.log('Received Cognito 2FA Code:', code)
          this.setup2FAcode = code
          this.dialog2FA = true
        })
        .catch(err => {
          console.log('Error retriving 2FA Code', err)
          const notification = {
            type: 'error',
            message: err.message
          }
          this.$store.dispatch('addNotification', notification)
        })
    },
    async confirm2FA() {
      let user = await Auth.currentAuthenticatedUser()
      await Auth.verifyTotpToken(user, this.verificationCode)
        .then(() => Auth.setPreferredMFA(user, 'TOTP'))
        .then(() => {
          console.log('Cognito 2FA Code successful confirmed:')
          this.$store.dispatch('syncCognitoUserState')
          this.dialog2FA = false
          this.verificationCode
        })
        .catch(err => {
          console.log('Error confirming 2FA', err)
          this.dialog2FA = false
          this.verificationCode
          const notification = {
            type: 'error',
            message: err.message
          }
          this.$store.dispatch('addNotification', notification)
        })
    },
    async disable2FA() {
      let user = await Auth.currentAuthenticatedUser()
      await Auth.verifyTotpToken(user, this.verificationCode)
        .then(() => Auth.setPreferredMFA(user, 'NOMFA'))
        .then(() => {
          console.log('Cognito 2FA successful disabled')
          this.$store.dispatch('syncCognitoUserState')
          this.dialogDisable2FA = false
          this.verificationCode = ''
          const notification = {
            type: 'success',
            message: 'disable 2FA successful'
          }
          this.$store.dispatch('addNotification', notification)
        })
        .catch(err => {
          console.log('Error disabling 2FA', err)
          this.dialogDisable2FA = false
          this.verificationCode
          const notification = {
            type: 'error',
            message: err.message
          }
          this.$store.dispatch('addNotification', notification)
        })
    },
    async changePassword() {
      let user = await Auth.currentAuthenticatedUser()
      await Auth.changePassword(user, this.oldPassword, this.newPassword)
        .then(() => {
          console.log('Password change successful')
          const notification = {
            type: 'success',
            message: 'Password change successful'
          }
          this.$store.dispatch('addNotification', notification)
          this.dialogPassword = false
          this.oldPassword = ''
          this.newPassword = ''
          this.reNewPassword = ''
        })
        .catch(err => {
          console.log('Error changing password:', err)
          const notification = {
            type: 'error',
            message: err.message
          }
          this.$store.dispatch('addNotification', notification)
        })
    }
  },
  computed: {
    profile: {
      get() {
        return this.advisor.profile
      },
      set(attributes) {
        this.$store.commit('SET_ADVISOR_PROFILE', attributes)
      }
    },
    codeStr2FA() {
      return (
        'otpauth://totp/AWSCognito:' +
        this.cognitoUserName +
        '?secret=' +
        this.setup2FAcode +
        '&issuer=' +
        'Falcon9'
      )
    },
    passwordConfirmationRule() {
      return () => this.password === this.rePassword || 'Passwords do not match'
    },
    ...mapState(['auth', 'advisor']),
    ...authComputed
  }
}
</script>

<style lang="scss" scoped></style>
